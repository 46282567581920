import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Тhalassa Beach Resort/1v1.jpg";
import pngFile2 from "./Тhalassa Beach Resort/2v1.jpg";
import pngFile3 from "./Тhalassa Beach Resort/3v1.jpg";
import pngFile4 from "./Тhalassa Beach Resort/4v1.jpg";
import pngFile5 from "./Тhalassa Beach Resort/5v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  More
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Тhalassa Beach Resort</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>Thalassa Beach Resort is located on the first coastline in the Bafra region and consists of studios, elite 1, 2, and 3-bedroom apartments, and penthouses with private rooftop terraces offering breathtaking views of the Mediterranean Sea. Each apartment's layout combines comfort with modern design. Ground-floor apartments feature private areas with individual pools, while second and third-floor apartments offer spacious terraces ideal for outdoor breakfasts and dinners. Penthouses boast large rooftop sun terraces with panoramic views of the surroundings.</div> 
        <br />
        <div>Location.</div>
        <br />
        <div>Nearby, there's a tourist center with shops, relaxation spots, and entertainment. Within a 40-minute drive, there's a championship golf course. A few minutes away is the fishing village of Bogaz, known for its seafood restaurants and a small but beautiful harbor.</div>
        <br />
        <div>Infrastructure:</div>
        <br />
        <div>-public pool and water park,</div>
        <div>-children's playground,</div>
        <div>-sandy beach,</div>
        <div>-sports courts,</div>
        <div>-game room,</div>
        <div>-restaurant,</div>
        <div>-fitness center and SPA.</div>
        <br />
        <div>6 Reasons to Invest in Thalassa:</div>
        <br />
        <div>BAFRA: A future tourist area with significant investments and VIP tourism development.</div>
        <br />
        <div>INTERNATIONALLY RECOGNIZED PROPERTY RIGHTS DOCUMENTS: The complex holds international land titles, ensuring buyers' safety and peace of mind.</div>
        <br />
        <div>EXCELLENT SEASIDE LOCATION: Thalassa is situated on a beautiful sandy beach.</div>
        <br />
        <div>HIGH RENTAL POTENTIAL: Owning property in Thalassa allows you to earn returns on your investments.</div>
        <br />
        <div>FIRST-CLASS SERVICES AND EXCLUSIVE BENEFITS FOR RESIDENTS: The complex's infrastructure is constantly updated and developed.</div>
        <br />
        <div>Payment options:</div>
        <br />
        <div>-100% payment,</div>
        <div>-60% initial deposit and 40% over 18 months.</div>
        <br />
        <div>Completion date: 2018.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
