import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Courtyard Long Beach/1v1.jpg";
import pngFile2 from "./Courtyard Long Beach/2v1.jpg";
import pngFile3 from "./Courtyard Long Beach/3v1.jpg";
import pngFile4 from "./Courtyard Long Beach/4v1.jpg";
import pngFile5 from "./Courtyard Long Beach/5v1.jpg";
import pngFile6 from "./Courtyard Long Beach/6v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  More
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Courtyard Long Beach</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile6} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>Resort hotel, built in 2021 on an area of 8000 square meters, is located on Long Beach. It combines an ideal geographical location, unique architecture, luxury, and comfort.</div> 
        <br />
        <div>On March 30, 2019, Courtyard received the prestigious North Cyprus Property NC Awards as the "Best New Project" and "Best Investment Project."</div>
        <br />
        <div>The convenience of the Courtyard complex lies in its name. Translated from English, Courtyard means "yard," which implies that you can enjoy all the services of the residential complex within the private area without the need to go far or step out of the "yard." If you're a fan of resort-style living, the luxury apartment complex Courtyard Long Beach is for you! It is located 600 meters from the sea in the Iskele region. The beach is sandy, public, equipped with exercise equipment for children and adults, a playground, cafes, bars, running, and biking tracks. The project offers apartments of the following layouts: studio, 1+1, 2+1, 3+1.</div>
        <br />
        <div>Infrastructure:</div>
        <br />
        <div>-outdoor Olympic-sized pool,</div>
        <div>-indoor pool,</div>
        <div>-fitness center and SPA,</div>
        <div>-children's pool,</div>
        <div>-kids' mini-club,</div>
        <div>-green area,</div>
        <div>-supermarket,</div>
        <div>-car rental,</div>
        <div>-play areas for children,</div>
        <div>-bar and restaurant,</div>
        <div>-laundry,</div>
        <div>-security, surveillance,</div>
        <div>-parking, and more.</div>
        <br />
        <div>100% payment.</div>
        <br />
        <div>Completion date: 2021.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
