import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Maldives Homes/1v1.jpg";
import pngFile2 from "./Maldives Homes/2v1.jpg";
import pngFile3 from "./Maldives Homes/3v1.jpg";
import pngFile4 from "./Maldives Homes/4v1.jpg";
import pngFile5 from "./Maldives Homes/5v1.jpg";
import pngFile6 from "./Maldives Homes/6v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  More
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Maldives Homes</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile6} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>Maldives Homes comprise unique villas and luxurious apartments situated on the east side of Kyrenia in Esentepe.</div> 
        <br />
        <div>Esentepe, renowned as the most popular village, is surrounded by numerous amenities, making it a rising star for real estate investments in Northern Cyprus.</div>
        <br />
        <div>The exotic villas and apartments at Maldives Homes boast magnificent, unobstructed views of the north and the blue Mediterranean Sea. Cyprus Construction, the developer, offers architecture and the highest level of finishing. The plot is a mere 20 meters from the sea, featuring a splendid lagoon utilized as a private beach.</div>
        <br />
        <div>Maldives Homes consists of 11 villas (2+1 and 3+1) and 64 apartments (1+1, 2+1, and 3+1).</div>
        <br />
        <div>General features and materials used in construction include:</div>
        <br />
        <div>thermal and sound insulation,
energy-efficient bricks,
double-glazed windows,
cold-resistant black aluminum frame,
modern glossy kitchen units with soft-close systems and granite countertops,
standard sanitary ware and premium-grade appliances for bathrooms and kitchens,
choice of large-format porcelain tiles in kitchen/bathrooms,
5-year construction warranty,
well-maintained gardens, fully landscaped,
complete air conditioning,
luxurious shower rooms and bathrooms en-suite,
high-quality built-in wardrobes with soft-close system,
terrace with integrated lighting and power sockets,
stone walls in front of the plot and pathways,
on-site parking,
central generator system.
</div>
<br />
<div>Infrastructure:</div>
<br />
<div>-luxurious swimming pool,</div>
<div>-private beach,</div>
<div>-restaurant from the renowned Califorian chain,</div>
<div>-SPA center,</div>
<div>-gym,</div>
<div>-sauna,</div>
<div>-beauty salon,</div>
<div>-massage parlor,</div>
<div>-sports facilities.</div>
<br />
<div>Each apartment offers breathtaking views of the mountains and the sea.</div>
<br />
<div>Payment: 100%.</div>
<br />
<div>Completion date: 2021.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
