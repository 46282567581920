import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Techstack1 from "../About/Techstack1/Techstack1";
import Techstack2 from "../About/Techstack2/Techstack2";
import Techstack3 from "../About/Techstack3/Techstack3";
import Techstack4 from "../About/Techstack4/Techstack4";
import Aboutcard from "./AboutCard";

import ScrollToTop from "../ScrollToTop/ScrollToTop";

import "./about.css";

function About() {
  return (
    <Container fluid className="about-section">
      <Container>
        <Row style={{ justifyContent: "center" }}>
          <Col
            md={10}
            style={{
              paddingBottom: "10%",
            }}
          >
            <h1
              style={{ paddingBottom: "20px", fontSize: "2.1em" }}
              data-aos="fade-right"
            >
              <span className="primary-header">Real estate</span>
            </h1>
            <p>
            The company offers you to explore the top 4 investment proposals.
            </p>
            <div className="aboutcard">
              <Aboutcard />
            </div>
          </Col>
        </Row>
        <h1
          data-aos="fade-right"
          style={{ paddingBottom: "20px", fontSize: "2.1em" }}
        >
          <span className="primary-header">
          Off-plan real estate
          </span>
        </h1>

        <div>
          <Techstack1 />
        </div>

        <h1
          data-aos="fade-right"
          style={{ paddingBottom: "20px", fontSize: "2.1em" }}
        >
          <span className="primary-header">Secondary real estate</span>
        </h1>

        <div data-aos="fade-up">
          <Techstack2 />
        </div>

        <h1 style={{ paddingBottom: "20px", fontSize: "2.1em" }}>
          <span className="primary-header">Land plot</span>
        </h1>

        <div data-aos="fade-up">
          <Techstack3 />
        </div>

        <h1 style={{ paddingBottom: "20px", fontSize: "2.1em" }}>
          <span className="primary-header">Health Spa Complexes</span>
        </h1>

        <div data-aos="fade-up">
          <Techstack4 />
        </div>

      </Container>
      {/* <ScrollToTop /> */}
    </Container>
  );
}

export default About;
