import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Panorama/1v1.jpg";
import pngFile2 from "./Panorama/2v1.jpg";
import pngFile3 from "./Panorama/3v1.jpg";
import pngFile4 from "./Panorama/4v1.jpg";
import pngFile5 from "./Panorama/5v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  More
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Panorama</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>Panorama - a new complex situated in the heart of the Iskele district, 150 meters from Long Beach. The project consists of two 12-story blocks comprising 111 luxurious apartments: studios, 1+1, 2+1, 3+1, and 4+1 duplexes, each with a unique configuration thanks to the building's architectural design.</div> 
        <br />
        <div>In addition to the standard WHITE BOX finish, customers are offered an additional package of functional solutions. Every detail in the apartments is meticulously thought out:</div>
        <br />
        <div>high-quality kitchen with quartz countertops (Belenco), central and additional ceiling lighting (Jupiter), heated floors in the bathroom, glass walk-in shower, shower set with a rain shower (Hansgrohe), console sink and toilet installation (Bocci), shower niche, towel holders, and chrome towel warmer, ventilation system with humidity control sensors.</div>
        <br />
        <div>The apartments are equipped with a ducted air conditioning system and household appliances from leading manufacturers (BOCSH/Samsung).</div>
        <br />
        <div>The top-floor observation deck with an infinity pool offers stunning views of both Famagusta Bay on one side and the panorama of the picturesque Karpaz Peninsula on the other.</div>
        <br />
        <div>Complex infrastructure:</div>
        <br />
        <div>-generator,</div>
        <div>-smoke and fire detectors,</div>
        <div>-24/7 security,</div>
        <div>-laundry service,</div>
        <div>-dry cleaning,</div>
        <div>-parking and wheelchair-accessible paths,</div>
        <div>-SPA center,</div>
        <div>-Turkish baths,</div>
        <div>-swimming pool,</div>
        <div>-gym,</div>
        <div>-kids' pool,</div>
        <div>-reception.</div>
        <br />
        <div>There is a possibility of a 12-month installment plan.</div>
        <br />
        <div>Completion date - August 2023.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
