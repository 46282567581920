import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Salamis Holiday Homes/1v1.jpg";
import pngFile2 from "./Salamis Holiday Homes/2v1.jpg";
import pngFile3 from "./Salamis Holiday Homes/3v1.jpg";
import pngFile4 from "./Salamis Holiday Homes/4v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  More
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Salamis Holiday Homes</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>The residential complex with 144 units, Salamis Holiday Homes, is located within walking distance of an elementary school. For enthusiasts of antiquity, the ancient city of Salamis, a cultural heritage site, is nearby. Opposite is Yeniboğaziçi Park, offering a promenade, cafes, restaurants, playgrounds for children of various age groups.</div> 
        <br />
        <div>Salamis is situated in the picturesque corner of Yeniboğaziçi, surrounded by the sea, forest, and park. One of the major advantages of Salamis Holiday Homes is its proximity to golden beaches and historical landmarks of the ancient city of Salamis. It's an ideal place for those seeking a blend of relaxation and adventure, promising new discoveries every day. Additionally, nearby are a college, school, and Near East kindergarten.</div>
        <br />
        <div>The Salamis Holiday Homes complex offers diverse housing, ranging from ground-floor studios with spacious private gardens, perfect for outdoor dining, to penthouses with panoramic terraces. Each apartment is designed to maximize space utilization and natural light, ensuring privacy and comfort.</div>
        <br />
        <div>The sea is 800 meters away.</div>
        <br />
        <div>The complex covers an extensive area of 21,000 m2 and includes:</div>
        <div>-restauran,</div>
        <div>-cafe-bar,</div>
        <div>-pools,</div>
        <div>-sports facilities,</div>
        <div>-children's pool,</div>
        <div>-green areas,</div>
        <div>-walking trails,</div>
        <div>-parking,</div>
        <div>-central generator,</div>
        <div>-CCTV system.</div>
        <br />
        <div>An in-house management company ensures asset security during property rental and provides occupancy reports.</div>
        <br />
        <div>Investors receive a two-year rental guarantee. In essence, if you purchase an apartment, you can lease it to the developer and receive passive income for two years. Another significant advantage of purchasing property here is that the apartment comes fully furnished, and home appliances are offered as gifts from the developer.</div>
        <br />
        <div>Payment plan:</div>
        <br />
        <div>-£5,000 deposit,</div>
        <div>-10% initial payment,,</div>
        <div>-10% payment after 3 months,</div>
        <div>-10% payment after 6 months,</div>
        <div>-30% payment over 17 months from the date of the 3rd payment,</div>
        <div>-35% balance over 12 months on interest-free installments or over 120 months using a loan with interest.</div>
        <br />
        <div>Keys will be handed over after full payment!</div>
        <br />
        <div>Completion Date: July 2025.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
