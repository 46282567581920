import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Four Seasons Life 1/1v1.jpg";
import pngFile2 from "./Four Seasons Life 1/2v1.jpg";
import pngFile3 from "./Four Seasons Life 1/3v1.jpg";
import pngFile4 from "./Four Seasons Life 1/4v1.jpg";
import pngFile5 from "./Four Seasons Life 1/5v1.jpg";
import pngFile6 from "./Four Seasons Life 1/6v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  More
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Four Seasons Life 1</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile6} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>Four Seasons Life is a new residential complex of modern, beautiful apartments, and villas, right on the beautiful sandy beach in the Boaz region, just a 15-minute drive from the historic city of Famagusta. The residential complex is built on 102,000 square meters of land, with 65,000 square meters dedicated to green areas, including 392 residential homes and 28 commercial units. Four Seasons Life offers various accommodation options: studios, 1 or 2-bedroom apartments, and 2 or 3-bedroom duplexes and villas.</div> 
        <br />
        <div>The Boaz area is highly attractive for investment, with land prices and real estate properties rapidly appreciating. The complex is situated on spacious, landscaped grounds on the first coastline. At its center, there's a pool and a relaxation area.</div>
        <br />
        <div>Infrastructure includes:</div>
        <br />
        <div>-children's playground,</div>
        <div>-SPA and gym,</div>
        <div>-bike path,</div>
        <div>-restaurant, snack bar, ,</div>
        <div>-beach bar,</div>
        <div>-water park,</div>
        <div>-walking area,</div>
        <div>-24/7 security and surveillance,</div>
        <div>-generator,</div>
        <div>-shops.</div>
        <br />
        <div>FOUR SEASONS LIFE offers comfortable, safe, and luxurious living in the midst of nature.</div>
        <br />
        <div>100% payment.</div>
        <br />
        <div>Completion date: December 2021.</div>
        </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
