import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Kisland/1v1.jpg";
import pngFile2 from "./Kisland/2v1.jpg";
import pngFile3 from "./Kisland/3v1.jpg";
import pngFile4 from "./Kisland/4v1.jpg";
import pngFile5 from "./Kisland/5v1.jpg";
import pngFile6 from "./Kisland/6v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  More
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>K-island</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile6} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>The K-Island complex is located in one of the most promising areas. Nearby, there are developments in the business and premium segments. Breathtaking views of the mountains and the sea will captivate both guests and residents of the complex. The project will feature six unique and individually inspired islands, each with its distinctive theme and charm, offering stunning views of the lagoon and landscaped gardens. The complex includes a wide variety of property types, ranging from studio apartments to private villas with pools.</div>
        <br />
        <div>Within a 5-minute drive, Tatlisu offers an array of amenities including an elementary school, restaurants, cafes, bars, parks, sports and children's playgrounds, shops, markets, post office, police station, pharmacy, and a municipal hospital.</div>
        <br />
        <div>The internal infrastructure caters to many resident needs:</div>
        <br />
        <div>-a landscaped, enclosed area,</div>
        <div>-a lounge for residents and guests with a library, fireplace, party room,</div>
        <div>-co-working spaces,</div>
        <div>-restaurant and cafe,</div>
        <div>-fitness club and spa complex,</div>
        <div>-wellness center,</div>
        <div>-tennis and basketball courts,</div>
        <div>-yoga area,</div>
        <div>-kids' club and playground.</div>
        <br />
        <div>A dedicated management company ensures asset security during the rental period and provides reports on occupancy.</div>
        <br />
        <div>By purchasing property in the exclusive K-Islands project and paying the full amount upfront, you automatically receive a 5% discount off the original price! This is your chance to own exceptional real estate at an even more attractive price.</div>
        <br />
        <div>Payment plan:</div>
        <br />
        <div>-5% deposit of the property cost,</div>
        <div>-35% down payment,</div>
        <div>-60% interest-free installment until the completion of construction.</div>
        <br />
        <div>Completion Date: June 2026.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
