import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  BsTelegram,
  BsPlayBtnFill,
  BsWhatsapp,
  BsTelephone,
  BsInstagram,
} from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { FaRegPaperPlane } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid id="" style={{ textAlign: "center" }}>
      <Container>
        <Row>
          <Col md={12} className="" style={{ marginTop: "4%" }}>
            <h1>
              <span className="primary-header">Northern Cyprus</span>
            </h1>
            <p
              className="home-about-body"
              data-aos="fade-up"
              style={{ textAlign: "left" }}
            >
              <div>
              Northern Cyprus, or the Turkish Republic of Northern Cyprus, is located in the northeastern part of the Mediterranean Sea. This is where world civilization originated, and to be convinced of this, one needs to visit at least once. The island's climate is unique, almost paradisiacal, and this is one of Cyprus' main advantages over other resort countries.
              </div>
              <br />
              <div>The most attractive real estate markets are Famagusta, Iskele-Long Beach, Yeni-Boazici, Boaz, Tatlisu, Esentepe, Kyrenia, Alsancak, Lapta, and Nicosia. There is a vast number of new developments here, the best golden beaches, and various types of housing with sea views ranging from 1000 to 4000 euros per square meter.
              </div>
              <br />
              <div>Buying property in this part of the island can be for both family living and rental purposes. Thanks to the abundance of sunny days per year (340) and infrastructure development, the flow of tourists is steadily increasing. 
              </div>
            </p>
          </Col>
          {/* <Col md={4} className="myAvtar">
            <Tilt>
              <img
                data-aos="fade-left"
                src={myImg}
                className="img-fluid"
                alt="avatar"
              />
            </Tilt>
          </Col> */}
        </Row>
        <Row>
          <Col
            md={12}
            className="home-about-social"
            style={{ paddingTop: 70, paddingBottom: 50 }}
          >
            <h1 data-aos="fade-right">
              <span className="primary-header">Contact Us</span>
            </h1>
            <p data-aos="fade-left">Get in touch with us.</p>
            <ul className="home-about-social-links" data-aos="fade-up">
              <li className="social-icons">
                <a
                  href="https://t.me/whiteagencyestate"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="mail"
                >
                  <AiOutlineMail />
                </a>
              </li>
              {/* <li className="social-icons">
                <a
                  href="https://www.youtube.com/@white_estate"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="youtube"
                >
                  <BsPlayBtnFill />
                </a>
              </li> */}
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/whiteestate_agency/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                  aria-label="instagram"
                >
                  <BsInstagram />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                  aria-label="phone"
                >
                  <BsTelephone />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
