import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Mikanos Homes/1v1.jpg";
import pngFile2 from "./Mikanos Homes/2v1.jpg";
import pngFile3 from "./Mikanos Homes/3v1.jpg";
import pngFile4 from "./Mikanos Homes/4v1.jpg";
import pngFile5 from "./Mikanos Homes/5v1.jpg";
import pngFile6 from "./Mikanos Homes/6v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  More
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Mykonos Homes</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile6} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>Mykonos Homes is a unique and luxurious new project by Cyprus Construction. Located near the Maldives Homes project in Esentepe, one of the most popular villages surrounded by numerous amenities. The conceptual design of the complex was inspired by the renowned Cavo Tagoo hotel in Greece.</div> 
        <br />
        <div>Mykonos Homes comprises 8 blocks of two-story apartments offering luxurious 1+1, 2+1, 3+1 apartments and penthouses on the ground floor. Additionally, there are 8 villas situated on the first line.</div>
        <br />
        <div>A distinctive feature is that each apartment and villa has its own private pool or jacuzzi. Additionally, there is a communal heated pool, a fitness center, sauna, botanical garden, beach cinema, and mini-golf. All apartment owners have access to the amenities of other complexes by the same developer nearby. Transfer services to the supermarket and golf club are provided. All homes are constructed with thermal and sound insulation.</div>
        <br />
        <div>Infrastructure of the complex:</div>
        <br />
        <div>-indoor pool,</div>
        <div>-gym,</div>
        <div>-SPA center,</div>
        <div>-private beach and beach cinema,</div>
        <div>-water sports,</div>
        <div>-tennis court,</div>
        <div>-mini-golf,</div>
        <div>-first aid room,</div>
        <div>-children's playground,</div>
        <div>-restaurant,</div>
        <div>-café and bar,</div>
        <div>-poolside bar,</div>
        <div>-management company,</div>
        <div>-24/7 security.</div>
        <br />
        <div>Payment in full.</div>
        <br />
        <div>Completion date: 2022.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
