import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Alpine Hills/1v1.jpg";
import pngFile2 from "./Alpine Hills/2v1.jpg";
import pngFile3 from "./Alpine Hills/3v1.jpg";


import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  More
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Alpine Hills</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>Presenting the new exclusive boutique project, Alpine Hills, located in Tatlisu. Surrounded by stunning views of the sea and mountains, it's situated in one of North Cyprus's greenest regions, an ideal place for those seeking a tranquil and comfortable lifestyle. Here, everything is set for you to enjoy your time.</div> 
        <br />
        <div>Infrastructure:</div>
        <br />
        <div>-enclosed area surrounded by olive groves,</div>
        <div>-2 communal pools,</div>
        <div>-children's playground,</div>
        <div>-gym,</div>
        <div>-sauna,</div>
        <div>-café and vitamin bar,</div>
        <div>-market,</div>
        <div>-generator,</div>
        <div>-drinking water reservoir,</div>
        <div>-mini-golf.</div>
        <br />
        <div>Distance to the sea: 500 meters.</div>
        <div>Distance to Ercan Airport: 35 km.</div>
        <div>Distance to Kyrenia: 24 km.</div>
        <br />
        <div>The complex comprises:</div>
        <br />
        <div>-4 villas, 6 townhouses (3+1 and 2+1), all villas and townhouses have their own pools, upper terraces equipped with relaxation areas, barbecue spaces, pergolas, private parking spots, and green areas around the perimeter,</div>
        <br />
        <div>-8 studios,</div>
        <div>-12 1+1 apartments,</div>
        <div>-8 1+1 loft apartments,</div>
        <div>-12 2+1 loft apartments.</div>
        <br />
        <div>Each apartment features premium-class finishing, Hansgrohe faucets, underfloor heating, standard air conditioning.</div>
        <br />
        <div>Payment plan:</div>
        <br />
        <div>-£5,000 deposit,</div>
        <div>-35% initial payment within 1 month,</div>
        <div>-25% before receiving the keys,</div>
        <div>-35% - interest-free installment for 1.5-2 years.</div>
        <br />
        <div>Prices for studios start from £95,000.</div>
        <br />
        <div>All payment conditions are negotiated individually. The developer is open to compromises and considers all customer preferences.</div>
        <br />
        <div>Upon purchasing a 2+1 apartment, villa, or townhouse, we offer a valuable gift that will undoubtedly be useful! Find out more about this gift from our managers.</div>
        <br />
        <div>Friends, there has never been such a project where it's halfway built, yet prices are as low as during the excavation phase! By the way, the cost of studios is particularly appealing as it's the lowest price for a studio in this region - £150,000. Therefore, hurry to make a wise investment in your future!</div>
        <br />
        <div>Completion date: May-August 2024.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
