import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import ProjectCard from "./ProjectCards";

import Education from "../../Assets/Projects/Education.png";
import Passport from "../../Assets/Projects/Passport.png";
import Relocation from "../../Assets/Projects/Relocation.png";
import Lawyer from "../../Assets/Projects/Lawyer.png";
import Dollar from "../../Assets/Projects/Dollar.png";
import Eye from "../../Assets/Projects/Eye.png";
import Pen from "../../Assets/Projects/Pen.png";
import Med from "../../Assets/Projects/Med.png";
import Buy from "../../Assets/Projects/Buy.png";

import { 
  BsWhatsapp,
  BsTelephone,
  BsInstagram
 } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { FaRegPaperPlane } from "react-icons/fa";


import "./project.css";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Container>
      <h1
              style={{ fontSize: "2.1em", paddingBottom: "20px" }}
              data-aos="fade-right"
            >
              <span className="primary-header">Services</span>
            </h1>
        <p>We offer the following services.</p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

        <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Buy}
              title="Property Purchase"
              description="Our property prices, construction, and services stand out favorably from those currently offered in Northern Cyprus. Meanwhile, the quality and execution timelines remain very high, something you can easily verify by comparing us with any other company on the island. White Estate is an official company registered in the territory of the Turkish Republic of Northern Cyprus."
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Passport}
              title="Residency Services"
              description="We provide consultancy on residency and citizenship applications, assisting with the paperwork when purchasing property. The processing time for residency through our company takes 14-20 days. Special permission is required for employment or business activities. Additionally, we can open a bank account within one day and assist in obtaining citizenship not only in Cyprus but also in other countries."
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Eye}
              title="Viewing Tour"
              description="Hotel or apartment reservations, airport transfers, island tours, visits to prime real estate properties and leading developers. Our company offers a free viewing tour for 3-6 days. We arrange presentations at popular residential complexes in Northern Cyprus, visit properties and construction sites for you to personally assess the quality of construction materials, meet with developers, and have an enjoyable time."
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Lawyer}
              title="Legal Property Check"
              description="A legal property check (ownership verification, any existing encumbrances, legality of the construction). It is recommended to have comprehensive legal assistance when purchasing real estate in Cyprus through a licensed professional lawyer. This ensures that your rights are explicitly guaranteed under the relevant laws of Cyprus."
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Relocation}
              title="Business Relocation"
              description="We assist foreign individuals and legal entities in opening and relocating businesses and employees. This service addresses all aspects related to relocating to another country, relaunching or establishing a new business, and adapting to a new environment. Business relocation to Northern Cyprus is one of the sought-after avenues for anyone aiming to develop their business without limitations and striving to create an international brand."
            />
          </Col>
           
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Dollar}
              title="Rental Services"
              description="We assist in finding rental apartments for you and your family, and we also help in renting transportation. If you have purchased property for future rental purposes, we take on responsibilities for finding tenants, advertising, their settlement, inventory checks, and providing a comprehensive report on the property's condition."
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Pen}
              title="Interior Design"
              description="Interior design services and elite design projects implemented under favorable conditions. If you've been contemplating changing your interior, but the thought of traveling between stores in search of materials and furniture, the necessity of overseeing the construction team, and uncertain timelines are daunting, then our service of developing a design project for your apartment, house, or commercial property can help."
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Med}
              title="Medical Tourism"
              description="European medical services and high levels of service are available to everyone. People come here from countries like Turkey, Germany, the CIS, Lithuania, Latvia, Estonia, Romania, and Bulgaria. The most popular medical services in Cyprus currently include the following: cosmetic surgery, gynecology and obstetrics, orthopedic procedures and trauma services, general body diagnostics, and reproductive technologies."
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Education}
              title="Education"
              description="Providing students with all the necessary support from the moment they choose a study program and university. Education in Northern Cyprus is a sought-after and popular service. Modern areas, new universities, affordable tuition fees, high-quality education that is valued in Europe—all within an amazing climate."
            />
          </Col>

        </Row>
      </Container>
      <Row>
          <Col md={12} className="home-about-social" style={{ paddingTop: 50, paddingBottom: 50 }}>
            <h1 data-aos="fade-right">
              <span className="primary-header">Contact Us</span>
            </h1>
            <p data-aos="fade-left">Get in touch with us.</p>
            <ul className="home-about-social-links" data-aos="fade-up">
              <li className="social-icons">
                <a
                  href="https://t.me/whiteagencyestate"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane/>
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="mail"
                >
                  <AiOutlineMail />
                </a>
              </li>
              {/* <li className="social-icons">
                <a
                  href="https://www.youtube.com/@white_estate"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="youtube"
                >
                  <BsPlayBtnFill />
                </a>
              </li> */}
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/whiteestate_agency/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                  aria-label="instagram"
                >
                  <BsInstagram />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                  aria-label="phone"
                >
                  <BsTelephone />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      {/* <ScrollToTop /> */}
    </Container>
  );
}

export default Projects;
