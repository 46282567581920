import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./4S/1v1.jpg";
import pngFile2 from "./4S/2v1.jpg";
import pngFile3 from "./4S/3v1.jpg";
import pngFile4 from "./4S/4v1.jpg";
import pngFile5 from "./4S/5v1.jpg";
import pngFile6 from "./4S/6v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  More
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Four Seasons Life 3</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile6} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>A brand-new luxurious project is located just a few minutes' walk from the sandy beach in the Bogaz region of Iskele, Northern Cyprus. It's an ideal location for those wanting proximity to nature yet not far from the city. The convenient location and rich infrastructure of the complex guarantee an excellent investment opportunity in Northern Cyprus.</div>
        <br />
        <div>The variety of amenities and services within the complex provide a wide range of entertainment for residents of all ages. This project is suitable for permanent residence or seasonal vacation.</div>
        <br />
        <div>Four Seasons Life 3 comprises 738 residences of spacious apartments with modern designs. Available options include studio apartments with separate rooftop terraces, 1+1 apartments with a garden and a rooftop garden, as well as 2+1 apartments with a garden or rooftop terrace.</div>
        <br />
        <div>Infrastructure:</div>
        <br />
        <div>facilities for people with limited mobility, outdoor and indoor pools, children's pool, water park, cycling and walking paths, fitness center, SPA and massage parlors, tennis, basketball, and volleyball courts, hair salon, disabled parking, 24/7 security, parking lot.</div>
<br />
<div>Each apartment is equipped with everything necessary for comfortable living:</div>
<br />
<div>-central generator,</div>
<div>-american kitchen, </div>
<div>-built-in wardrobes,</div>
<div>-air conditioning system,</div>
<div>-steel front doors,</div>
<div>-PVC windows,</div>
<div>-central satellite and internet systems,</div>
<div>-video intercom.</div>
<br />
<div>Payment plan:</div>
<br />
<div>-5% of the property cost,</div>
<div>-35% initial payment,</div>
<div>-6% interest-free installment until the completion of construction.</div>
<br />
<div>Completion Date: January 2026.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
