import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./La Joya Perla/1v1.jpg";
import pngFile2 from "./La Joya Perla/2v1.jpg";
import pngFile3 from "./La Joya Perla/3v1.jpg";
import pngFile4 from "./La Joya Perla/4v1.jpg";
import pngFile5 from "./La Joya Perla/5v1.jpg";
import pngFile6 from "./La Joya Perla/6v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  More
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>La Joya Perla</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile6} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>The complex with 384 units, La Joya Perla, is being developed beyond the high-rise buildings and dense construction of the Iskele (Long Beach) area. The district is actively growing. Long Beach, the most recognizable beach, is located 3.8 km away.</div> 
        <br />
        <div>For enthusiasts of walking and running, there's a promenade along the beach. Children's and sports playgrounds for various age groups, as well as an area for mini-golf, are available. Pharmacies, shops, cafes, restaurants, picnic areas, Beach Club Pera, and Luna Park - all are within reach for residents.</div>
        <br />
        <div>This project is located in a picturesque spot overlooking the Mediterranean Sea, granting its residents access to the La Joya Beach Club, where they can enjoy various entertainments and amenities. The complex comprises 384 luxurious residences, each surrounded by an exquisite pool with a unique bar in the center, creating an atmosphere of relaxation and pleasure.</div>
        <br />
        <div>The homes are equipped with a "smart home" system allowing control of heating, cooling, and lighting via mobile phones. With doorbell cameras and a security system, residents can feel secure. La Joya Perla also offers direct access to the La Joya Beach Club, where you can enjoy premium-class restaurants, bars, spa centers, and many other amenities. Situated in the Long Beach Iskele region, it's an ideal place for real estate investment in Northern Cyprus due to its excellent location and access to social amenities.</div>
        <br />
        <div>Property features:</div>
        <br />
        <div>-glossy kitchen finish in three color options,</div>
        <div>-double-glazed aluminum,</div>
        <div>-natural marble or travertine windowsills,</div>
        <div>-choice of parquet or ceramic flooring in three color options,</div>
        <div>-american panel doors,</div>
        <div>-steel staircase with wooden steps,</div>
        <div>-heated floors in bathrooms,</div>
        <div>-central heating and cooling in the house,</div>
        <div>-'Smart home' systems (lighting and heating/cooling control).</div>
        <br />
        <div>Infrastructure:</div>
        <br />
        <div>-open swimming pool,</div>
        <div>-waterpark,</div>
        <div>-covered swimming pool,</div>
        <div>-playground,</div>
        <div>-basketball court,,</div>
        <div>-mini-market,</div>
        <div>-SPA and gym,</div>
        <div>-unisex hairdresser,</div>
        <div>-restaurant,</div>
        <div>-poolside bar,</div>
        <div>-children's club,</div>
        <div>-open amphitheater/cinema,</div>
        <div>-central generator,</div>
        <div>-central satellite,</div>
        <div>-central internet.</div>
        <br />
        <div>Payment plan:</div>
        <br />
        <div>-£5000 deposit,</div>
        <div>-45% - prior to receiving keys,</div>
        <div>-20% - interest-free installment after receiving keys.</div>
        <br />
        <div>Completion date: November 2026.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
