import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Natulux/1v1.jpg";
import pngFile2 from "./Natulux/2v1.jpg";
import pngFile3 from "./Natulux/3v1.jpg";
import pngFile4 from "./Natulux/4v1.jpg";
import pngFile5 from "./Natulux/5v1.jpg";
import pngFile6 from "./Natulux/6v1.jpg";

import "./ImageModal.css";

function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  More
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Natulux</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile6} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>Natulux is a premium-class complex, an ideal place for luxurious living and investment opportunities in Northern Cyprus. It is situated in the picturesque area of Tatlisu, offering breathtaking views of the Mediterranean Sea and lush greenery of the surrounding mountains.</div>
        <br />
        <div>Located on the first coastline, the complex provides an oasis of tranquility and comfort where you can bask in the warm sun and sea breeze. Nearby lies the Karpas Peninsula, renowned for Alagadi Turtle Beach, also known as Turtle Beach.</div>
        <br />
        <div>Natulux resides in the fast-growing region of Kucuk Erenkoy, a small and peaceful area that attracts tourists and property buyers with its scenic views, proximity to the sea, and relative distance from the urban hustle. Similar to many places in Northern Cyprus, Kucuk Erenkoy blends traditional Cypriot charm with modern conveniences due to the burgeoning real estate sector and increasing residential complexes like Natulux, offering comfortable housing with various amenities and beautiful views. Such developments make this region attractive to foreign investors and those seeking homes away from city life.</div>
        <br />
        <div>Residents of Natulux can enjoy a wide range of amenities and entertainment within the complex, including:</div>
        <br />
        <div>-outdoor and indoor pools,</div>
        <div>-mini-golf,</div>
        <div>-open-air cinema,</div>
        <div>-SPA,</div>
        <div>-gym,</div>
        <div>-BBQ area.</div>
        <br />
        <div>Payment plan:</div>
        <br />
        <div>-5% deposit of the property cost,</div>
        <div>-35% down payment,</div>
        <div>-60% interest-free installment until the completion of construction.</div>
        <br />
        <div>Completion Date: June 2026.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
