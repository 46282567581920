import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import ProjectCard from "./ProjectCard";
import ProjectCard2 from "./ProjectCard2";
import ProjectCard3 from "./ProjectCard3";
import ProjectCard4 from "./ProjectCard4";
import ProjectCard5 from "./ProjectCard5";
import ProjectCard6 from "./ProjectCard6";
import ProjectCard21 from "./ProjectCard 21";
import ProjectCard22 from "./ProjectCard 22";
import ProjectCard23 from "./ProjectCard 23";
import ProjectCard24 from "./ProjectCard 24";
import ProjectCard25 from "./ProjectCard 25";
import ProjectCard26 from "./ProjectCard 26";

import ProjectCard11 from "./ProjectCard11";
import ProjectCard12 from "./ProjectCard12";
import ProjectCard13 from "./ProjectCard13";
import ProjectCard14 from "./ProjectCard14";
import ProjectCard15 from "./ProjectCard15";
import ProjectCard16 from "./ProjectCard16";

import Home2 from "./Home2";
import Home3 from "./Home3";
import Sponsors from "./Sponsors";
import Type from "./Type";
import "./home.css";
import ImageModal from "./YouTube/ImageModal1";
import LEDSign from "./LEDSign";
import "./LEDSign.css";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
          <Row>
            <Col className="home-header">
              <h1 style={{ paddingBottom: 5 }} className="heading">
                Welcome to{" "}
              </h1>

              <h1 className="heading-name">
                <strong className="main-name" style={{ fontWeight: "900" }}>
                  {" "}
                  White Estate
                </strong>
              </h1>
              <div style={{ padding: 0 }} className="type">
                <Type />
              </div>
              <Col className="myAvtar2">
                <ImageModal />
              </Col>
              <Col className="QR"></Col>
            </Col>
          </Row>
        </Container>
      </Container>
      <LEDSign />
      <Container>
        <Row
          className="coolcards"
          style={{ justifyContent: "center", paddingTop: "5%" }}
        >
          <h1 data-aos="fade-right">
            <span className="primary-header">
              {" "}
              Off-plan sales{" "}
            </span>
          </h1>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              title="Sapphire Blue"
              description="Grand Sapphire Blu is the continuation of the highest project in Northern Cyprus - Grand Sapphire Resort."
              description2="Price: starting from £89,950."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard2
              title="K-island"
              description="The K-Island complex is situated in the Tatlisu area and is designed to ensure maximum comfort, incorporating all modern architectural and design trends."
              description2="Price: starting from £157,000. "
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard3
              title="Natulux"
              description="The premium-class Natulux complex is the perfect place for luxurious living and investment opportunities in Northern Cyprus."
              description2="Price: starting from £199,000."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard4
              title="Kaia Residence"
              description="Situated just steps away from the serene beach and not far from local villages, the Kaia project offers residents and island visitors a unique blend of eco-friendliness and resort-style luxury."
              description2="Price: starting from £166,750."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard5
              title="Four Seasons Life 3"
              description="Four Seasons Phase 3 is situated right on the first coastline in the Boaz area. This area is highly appealing for investment as land prices and real estate properties are rapidly escalating."
              description2="Price: starting from £130,000."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard6
              title="Querencia"
              description="The Querencia project will be located 400 meters away from the picturesque Mediterranean Sea beach. It will be a magnificent addition to the coastline, offering residents of the complex unique view apartments combined with excellent infrastructure."
              description2="Price: starting from £134,000. "
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard21
              title="Alpine Hills"
              description="Introducing the new exclusive boutique project, Alpine Hills, located in Tatlisu and surrounded by stunning views of the sea and mountains."
              description2="Price: starting from £95,000"
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard22
              title="Orchard 3"
              description="Orchard is a complex located in Yeniboğaziçi, emphasizing a tranquil lifestyle surrounded by greenery, just a few kilometers from Famagusta."
              description2="Price: starting from £104,000."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard23
              title="Dolce Vita"
              description="A large low-rise business-class complex comprising 408 units, Dolce Vita, is situated near Long Beach and offers its residents an area for living with maximum internal infrastructure."
              description2="Price: starting from £115,000."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard24
              title="La Joya Perla"
              description="The complex with 384 units of La Joya Perla is being constructed away from high-rise buildings and dense construction in the location of Iskele (Long Beach)."
              description2="Price: starting from £109,900. "
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard25
              title="Salamis Holiday Homes"
              description="The residential complex with 144 units, Salamis Holiday Homes, is located within walking distance of the elementary school."
              description2="Price: starting from £95,000."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard26
              title="La Casalia"
              description=" The exclusive complex La Casalia will be situated on 260 donums of land (340,000 m2)."
              description2="Price: starting from £182,000."
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row style={{ justifyContent: "center", paddingTop: "5%" }}>
          <h1 data-aos="fade-right">
            <span className="primary-header">Completed objects sales</span>
          </h1>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard11
              title="Four Seasons Life 1"
              description="Four Seasons Life is a new residential complex of modern, beautiful apartments, and villas, right on the beautiful sandy beach in the Boaz region."
              description2="Price: starting from £300,000."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard12
              title="Тhalassa Beach Resort"
              description="The Thalassa Beach Resort complex is located on the first coastline in the Bafra region and consists of studios and luxury apartments."
              description2="Price: starting from £150,000."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard13
              title="Courtyard Long Beach"
              description="If you're a fan of resort-style living, the luxury apartment complex Courtyard Long Beach is perfect for you!"
              description2="Price: starting from £100,000."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard14
              title="Maldives Homes"
              description="The Maldives Homes complex consists of unique villas and luxurious apartments located on the east side of Kyrenia in Esentepe."
              description2="Price: starting from £425,000."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard15
              title="Panorama"
              description="Panorama - a new complex situated in the heart of the Iskele district, 150 meters from Long Beach. The project comprises two 12-story blocks, consisting of 111 luxurious apartments."
              description2="Price: starting from 180.000£."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard16
              title="Mykonos Homes"
              description="Inspired by the Greek island of Mykonos, a team of architects designed a luxurious project built in the Bahçeli area right on the coast of the Mediterranean Sea."
              description2="Price: starting from £400,000. "
            />
          </Col>
        </Row>
      </Container>
      <Container className="home2">
        <Home2 />
        <h1 style={{ paddingTop: "5%", paddingBottom: "2%" }}>
          <span className="primary-header"> Our Partners </span>
        </h1>
        <div className="sponsors" data-aos="fade-up">
          <Sponsors />
        </div>
        <div className="home3" style={{ paddingTop: "5%" }}>
          <Home3 />
        </div>
        {/* <ScrollToTop /> */}
      </Container>
    </section>
  );
}

export default Home;
