import React from "react";
import Card from "react-bootstrap/Card";
import { AiOutlineArrowRight } from "react-icons/ai";

function AboutCard() {
  return (
    <Card className="quote-card-view ">
      <Card.Body style={{ paddingBottom: "50px", paddingTop: "50px" }}>
        <blockquote className="blockquote mb-0" data-aos="zoom-in">
          <ul>
            <li className="about-activity">
              <AiOutlineArrowRight /> Real Estate at excavation stage (off-plan):
              <p
                style={{
                  fontSize: "15px",
                  paddingTop: "10px",
                  paddingLeft: "25px",
                }}
              >
                Yields of up to 20% annually. Closed sales, reliable developers.
              </p>
            </li>
            <li className="about-activity">
              <AiOutlineArrowRight /> Resale Real Estate:
              <p
                style={{
                  fontSize: "15px",
                  paddingTop: "10px",
                  paddingLeft: "25px",
                }}
              >
                Rental yield starting from a minimum of 10% annually. Ready-made business. 
              </p>
            </li>
            <li className="about-activity">
              <AiOutlineArrowRight /> Land Plots:
              <p
                style={{
                  fontSize: "15px",
                  paddingTop: "10px",
                  paddingLeft: "25px",
                }}
              >
                Twofold yield and realization of your projects.
              </p>
            </li>
            <li className="about-activity">
              <AiOutlineArrowRight /> Health Spa Complexes:
              <p
                style={{
                  fontSize: "15px",
                  paddingTop: "10px",
                  paddingLeft: "25px",
                }}
              >
                Income from sales and rentals + hotel management/services, timesharing.
              </p>
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
