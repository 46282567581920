import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Sapphire/1v1.jpg";
import pngFile2 from "./Sapphire/2v1.jpg";
import pngFile3 from "./Sapphire/3v1.jpg";
import pngFile4 from "./Sapphire/4v1.jpg";
import pngFile5 from "./Sapphire/5v1.jpg";
import pngFile6 from "./Sapphire/6v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  More
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Sapphire Blue</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile6} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>A large-scale business-class complex with 2,345 units, Grand Sapphire Resort Blu, is being constructed opposite the famous Long Beach and Pera Beach Club. A pathway for walking, running, or cycling enthusiasts is planned along the beach. There are children's playgrounds and an amusement park for kids of various age groups.</div> 
        <br />
        <div>All necessary infrastructure for comfortable living and leisure is within walking distance.</div>
        <br />
        <div>The complex offers residents:</div>
        <br />
        <div>-infinity rooftop pools for each multi-story block,</div>
        <div>-heated indoor pool, a sandy beach pool, a 4600m² "Punta Cana" pool, and a water park,</div>
        <div>-Fit Plus gym,</div>
        <div>-business center,</div>
        <div>-restaurant, bar, and cafes,</div>
        <div>-tennis court and sports facilities,</div>
        <div>-large children's playground,</div>
        <div>-supermarkets,</div>
        <div>-kids' club,</div>
        <div>-over 10 pools on-site, one of which is the LARGEST on the ENTIRE island,</div>
        <div>-open-air cinema,</div>
        <div>-beauty salon,</div>
        <div>-SPA salon,</div>
        <div>-pet area,</div>
        <div>-three-level parking: underground, on-ground, and above-ground,</div>
        <div>-shuttle service,</div>
        <div>-5-star hotel with a casino.</div>
        <br />
        <div>Advantages of purchasing property in Grand Sapphire BLU:</div>
        <br />
        <div>Investors will receive a two-year rental guarantee. Meaning, if you buy an apartment, you can lease it to the developer and earn passive income for two years. Another significant advantage of buying property is that the apartment comes fully furnished, and household appliances are included as a gift from the developer.</div>
        <br />
        <div>Payment plan:</div>
        <br />
        <div>-5% deposit of the property cost,</div>
        <div>-30% down payment within a month,</div>
        <div>-remaining 65% without interest in installments until September 2027.</div>
        <br />
        <div>Completion Date: September 2027.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
