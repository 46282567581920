import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/test3.gif";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 data-aos="fade-right">
              <span className="primary-header"> About Us </span>
            </h1>
            <p className="home-about-body" data-aos="fade-up">
              <div>
              "White Estate" is the leading real estate company in Northern Cyprus. Our firm specializes in real estate and consultations for Russian-speaking and foreign clients in Northern Cyprus.
              </div>
              <br />
              <div>
              White Estate is an official company registered within the territory of the Turkish Republic of Northern Cyprus. The legal name of the company is Hres Investment LTD.
              </div>
              <br />
              <div>
              We work for you with passion, integrity, and sunshine! With respect, the White Estate company.
              </div>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <img data-aos="fade-left" src={myImg} className="img-fluid" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
