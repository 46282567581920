import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import ProjectCard from "./ProjectCards";

import MapTag from "../../Assets/Projects/MapTag.png";
import PhoneTag from "../../Assets/Projects/PhoneTag.png";
import "./project.css";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1
          style={{ fontSize: "2.1em", paddingBottom: "20px" }}
          data-aos="fade-right"
        >
          <span className="primary-header">Our Contacts</span>
        </h1>
        <p>We are glad to welcome you to Northern Cyprus.</p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={MapTag}
              title="Location"
              description="Iskele Street 99850, Trikomo, North Cyprus"
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={PhoneTag}
              title="Phone"
              description="+90 533 865 15 75"
            />
          </Col>
        </Row>
      </Container>
      {/* <ScrollToTop /> */}
    </Container>
  );
}

export default Projects;
