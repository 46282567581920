import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Orchard3/1v1.jpg";
import pngFile2 from "./Orchard3/2v1.jpg";
import pngFile3 from "./Orchard3/3v1.jpg";
import pngFile4 from "./Orchard3/4v1.jpg";
import pngFile5 from "./Orchard3/5v1.jpg";
import pngFile6 from "./Orchard3/6v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  More
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Orchard 3</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile6} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>Orchard is a complex located in Yeniboğaziçi, emphasizing a tranquil lifestyle surrounded by greenery, just a few kilometers from Famagusta. At the heart of the project, there are several communal pools and playgrounds, as well as beautifully landscaped streets and parks. Additionally, there is an indoor pool, sauna, restaurant, gym, and supermarket.</div> 
        <br />
        <div>The third phase of the Orchard residential complex, consisting of 30 units, is located near the beach, a large park, and the Near East private school in Yeniboğaziçi.</div>
        <br />
        <div>A ten-minute drive provides access to all urban infrastructure: university, banks and currency exchange points, shops, shopping and entertainment centers, restaurants, cafes, etc. For history enthusiasts, ancient sites such as the ancient city of Salamis, the old town of Famagusta, and the abandoned Varosha are accessible for visits.</div>
        <br />
        <div>Orchard 3 covers an area of 20,000 square meters, featuring comfortable apartments and townhouses of various sizes, as well as standalone villas with three and four bedrooms, each having its own plot and pool. All properties are sold with finished interiors, fully equipped bathrooms, kitchens with built-in appliances, and storage closets in the bedrooms.</div>
        <br />
        <div>Complex infrastructure:</div>
        <div>-secured and landscaped area under video surveillance with numerous flowers and fruit trees,</div>
        <div>-fitness and spa center,</div>
        <div>-indoor and outdoor pools,</div>
        <div>-restaurant and bar with a barbecue area,</div>
        <div>-supermarket,</div>
        <div>-children's playground,</div>
        <div>-tennis court,</div>
        <div>-game area with billiards and ping-pong tables.</div>
        <br />
        <div>Key features:</div>
        <br />
        <div>-spacious living room with a large balcony,</div>
        <div>-kitchen with high-quality built-in furniture,</div>
        <div>-ceramic tiled flooring,</div>
        <div>-bedroom with a built-in wardrobe,</div>
        <div>-fully equipped bathroom,</div>
        <div>-high-quality ceramic tiles on the floor and walls in the bathroom,</div>
        <div>-infrastructure for installing air conditioners,</div>
        <div>-infrastructure for internet and television,</div>
        <div>-double-glazed panoramic windows.,</div>
        <div>-glass railings on balconies.</div>
        <br/>
        <div>A dedicated management company ensures asset security during the property's rental period and provides occupancy reports. The developer has paid particular attention to landscaping and plans to create a real oasis with numerous flowers and fruit trees.</div>
        <br />
        <div>Payment plan:</div>
        <br />
        <div>-£5,000 deposit,</div>
        <div>-35% down payment,</div>
        <div>-60% interest-free installments until the keys are received.</div>
        <br />
        <div>Completion date: December 2025.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
