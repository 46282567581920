import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./La Casalia/1v1.jpg";
import pngFile2 from "./La Casalia/2v1.jpg";
import pngFile3 from "./La Casalia/3v1.jpg";
import pngFile4 from "./La Casalia/4v1.jpg";
import pngFile5 from "./La Casalia/5v1.jpg";
import pngFile6 from "./La Casalia/6v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  More
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>La Casalia</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile6} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>Exclusive complex La Casalia will be situated on 260 donums of land (340,000 m2). It consists of 2 phases:</div> 
        <br />
        <div>-phase 1 - with a sea view;</div>
        <div>-phase 2 - forest and mountains.</div>
        <br />
        <div>The project includes 420 units, where 40% will be construction, and 60% will be devoted to fantastic infrastructure: a private marina, forest, and a beautiful beach.</div>
        <br />
        <div>The complex will be located in Esentepe, on the first coastline. The sandy beach, private marina, vast green areas, and forests make the complex's location attractive not only for those seeking investment opportunities but also for those looking to fulfill their dream of a home by the sea.</div>
        <br />
        <div>Infrastructure:</div>
        <br />
        <div>-private marina with scheduled boats from Esentepe to Kyrenia,</div>
        <div>-electric scooters for all residents to use across the complex,</div>
        <div>-garden area or space for planting trees and plants according to your preferences,</div>
        <div>-parking: underground and aboveground,</div>
        <div>-8 km of green areas (forests, trails),</div>
        <div>-private beach,</div>
        <div>-winery,</div>
        <div>-gym,</div>
        <div>-SPA,</div>
        <div>-business center,</div>
        <div>-restaurants, bars,</div>
        <div>-swimming pools,</div>
        <div>-children's playgrounds,</div>
        <div>-sports facilities.</div>
        <br />
        <div>Payment plan:</div>
        <br />
        <div>1.	Deposit amount varies by type:</div>
        <br />
        <div>-£5,000 for STD, 1+1, and 2+1 Apartments,,</div>
        <div>-£10,000 for 2+1 villas,,</div>
        <div>-£20,000 for 3+1 villas.</div>
        <br />
        <div>Effective from 20.11.2023, the deposit deadline for individual sales will be 1 month.</div>
        <br />
        <div>2.	Initial payment of 35%:</div>
        <div>-2% discount for 60% payment,</div>
        <div>-3% discount for 80% payment,</div>
        <div>-4% discount for 100% payment.</div>
        <br />
        <div>3.	Remaining amount until December 2027.</div>
        <br />
        <div>Completion date: December 2027.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
