import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Kai/1v1.jpg";
import pngFile2 from "./Kai/2v1.jpg";
import pngFile3 from "./Kai/3v1.jpg";
import pngFile4 from "./Kai/4v1.jpg";
import pngFile5 from "./Kai/5v1.jpg";
import pngFile6 from "./Kai/6v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  More
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Kaia Residence</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile6} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>KAIA is more than just a residential project—it's a comprehensive lifestyle concept carefully designed for residents.</div>
        <br />
        <div>This coastal complex offers various amenities to cater to all aspects of your life. Enjoy fine dining at the restaurant or relax at the poolside bar. Find opportunities for productive work and collaboration in co-working spaces or bask in the fresh air amidst green gardens. Visit the sandy beach, admire the view from the picturesque platform, or stroll along pedestrian and bicycle paths. Focus on your health in the yoga and fitness studios, spa, sauna, hydro massage baths, and let your children enjoy modern playrooms and playgrounds.</div>
        <br />
        <div>It represents a perfect combination of standalone houses, villas, and triplexes consisting of 1+1, 2+1, 3+1 apartments.</div>
        <br />
        <div>Location of the Complex.</div>
        <br />
        <div>Situated amidst the breathtaking landscapes of Northern Cyprus. Within a 60 km radius, you'll find numerous attractions catering to diverse interests, from the airport's proximity and the charm of local towns to historical treasures like castles and ancient sites, as well as appealing spots for hiking. Nestled amid untouched nature, this place is also renowned for the high quality of local produce, particularly olives and olive oil.</div>
        <br />
        <div>Property Location.</div>
        <br />
        <div>Three-bedroom lofts embody luxury, situated right on the untouched waterfront. These two-story villas are located on extensive seaside plots with living spaces boasting 4.6-meter-high ceilings.</div>
        <br />
        <div>The triplex homes, situated in the third row from the sea, represent exquisite design. These luxurious two-bedroom semi-detached homes span three floors, providing private seclusion on each level.</div>
         <br />
         <div>Courtyard homes with three bedrooms, located in the second row from the sea, offer flexibility, allowing modification of the third bedroom's function. It can be converted into a playroom or a home office.</div>
         <br />
         <div>Attached houses, 1+1 and 2+1, are situated from the second to the fourth rows from the sea. Ground-floor apartments delight with their private gardens, while second-floor apartments have rooftop terraces with splendid views of the pool or the sea.</div>
         <br />
         <div>Payment plan:</div>
         <br />
         <div>-40% initial payment upon contract signing,</div>
         <div>-60% paid in monthly or quarterly installments until the keys are handed over.</div>
         <br />
        <div>Completion Date: May 2025.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
