import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Dolce Vita/1v1.jpg";
import pngFile2 from "./Dolce Vita/2v1.jpg";
import pngFile3 from "./Dolce Vita/3v1.jpg";
import pngFile4 from "./Dolce Vita/4v1.jpg";
import pngFile5 from "./Dolce Vita/5v1.jpg";
import pngFile6 from "./Dolce Vita/6v1.jpg";

import "./ImageModal.css";


function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  More
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Dolce Vita</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile6} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>The extensive low-rise business-class complex with 408 units, 'Dolce Vita,' is situated near Long Beach, offering its residents a living space with maximum internal infrastructure.</div> 
        <br />
        <div>The complex will be located amidst nature along the coast in the Iskele region. The total area is 70,000 square meters, inclusive of all infrastructure, comprising 23 blocks, including 408 residential units and 8 commercial spaces.</div>
        <br />
        <div>This offers you the opportunity to live in one of the island's most popular areas - Iskele, surrounded by forests, greenery, and the endless sea.</div>
        <br />
        <div>You deserve this sweet life with all its privileges.</div>
        <br />
        <div>Residents will have access to amenities such as:</div>
        <br />
        <div>-gym,</div>
        <div>-SPA center,</div>
        <div>-game club,</div>
        <div>-vitamin bar,</div>
        <div>-restaurant and café-bar,</div>
        <div>-management office,</div>
        <div>-swimming pool,</div>
        <div>-walking track,</div>
        <div>-green spaces,</div>
        <div>-sports grounds.</div>
        <br />
        <div>The complex will feature:</div>
        <div>-jacuzzi,</div>
        <div>-surveillance system,</div>
        <div>-central generator,</div>
        <div>-automatic entry door,</div>
        <div>-central purification system,</div>
        <div>-central satellite,</div>
        <div>-security.</div>
        <br />
        <div>Layout types include:</div>
        <br />
        <div>-studios with terrace or garden up to 76 m2,</div>
        <div>-1+1 with terrace or garden up to 94 m2,</div>
        <div>-2+1 with terrace or garden up to 117 m2,</div>
        <div>-3+1 with terrace or garden up to 172 m2.</div>
        <br />
        <div>The sandy beach is located 200 meters away from the complex.</div>
        <br />
        <div>Payment plan:</div>
        <br />
        <div>-5% deposit,</div>
        <div>-35% down payment,</div>
        <div>-30% payment over 24 months until completion,</div>
        <div>-30% payment upon receiving the keys.</div>
        <br />
        <div>Completion date: first stage completion - June 2026, second stage completion - December 2026.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
