import React, { useState } from "react";
import { Modal, Image, Button, ModalHeader, ModalBody, Col, ModalFooter,Row } from "react-bootstrap";
import { 
  BsWhatsapp,
  BsTelephone
 } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa"; 
import { AiOutlineMail } from "react-icons/ai";
import pngFile1 from "./Q/1v1.jpg";
import pngFile2 from "./Q/2v1.jpg";
import pngFile3 from "./Q/3v1.jpg";
import pngFile4 from "./Q/4v1.jpg";
import pngFile5 from "./Q/5v1.jpg";
import pngFile6 from "./Q/6v1.jpg";

import "./ImageModal.css";

function ImageModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

     <a onClick={handleShow}>
              <Button
  variant="primary"
  target="_blank"
  style={{
  }}
>
  More
</Button>
      </a>
      <Modal show={show} onHide={handleClose} size="lg" style={{ paddingTop:"7em" }}>
       <ModalHeader style={{ fontSize:"1.3em" }}>Quarencia</ModalHeader>
       <ModalBody>
       <Image src={pngFile1} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile2} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile3} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile4} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile5} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Image src={pngFile6} style={{ paddingBottom:"1em" }} alt="White Estate" fluid />
       <Col style={{ paddingTop:"5%" }}>
        <div>"Querencia" complex offers luxury resort-style living in the trendiest region, just 400 meters from the picturesque Long Beach in Iskele, across from two five-star hotels with casinos on the first coastline.</div>
        <br />
        <div>The presence of two five-star hotels with casinos on the first coastline opposite the project gives it special appeal and makes it an ideal investment option.</div>
        <br />
        <div>Querencia comprises four towers of varying heights, one of which will be a luxurious hotel. The complex will feature 686 comfortable apartments of different types: studios, 1+1, 2+1, 3+1, as well as exquisite 4+1 and 5+1 penthouses.</div>
        <br />
        <div>Complex Infrastructure:</div>
        <br />
        <div>-outdoor and indoor pools covering an area of over 1300 m2,</div>
        <div>-infinity pool on the rooftop of the 10th floor,</div>
        <div>-water park for children,</div>
        <div>-co-working and leisure zones, conference halls,</div>
        <div>-currency exchange point,</div>
        <div>-cinema,</div>
        <div>-restaurants offering various world cuisines and a buffet breakfast hall,</div>
        <div>-snack bar,</div>
        <div>-gym and spa complex</div>
        <div>-turkish hammam, steam rooms, massage rooms.</div>
        <br />
        <div>A dedicated management company ensures asset security during the property's rental period and provides reports on occupancy.</div>
        <br />
        <div>The complex will feature outdoor recreational areas—children's activity zones, sports and play areas, a basketball court, squash court, and tennis court, as well as pedestrian and bicycle paths. Residents will have access to an underground passage leading to the beach infrastructure.</div>
        <br />
        <div>Payment plan:</div>
        <br />
        <div>-5% deposit of the property cost,</div>
        <div>-35% initial payment,</div>
        <div>-60% interest-free installment until the end of construction and one year after receiving the keys..</div>
<br />
<div>Completion Date: June 2025 - September 2026.</div>
       </Col>
       </ModalBody>
       <ModalFooter style={{ justifyContent:"center" }}>
        <div>
              <li className="social-icons">
                <a
                  href="mailto:kseniya@whiteestate.co"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?phone=905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="whatsapp"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/cavalle"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telegram"
                >
                  <FaRegPaperPlane />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+905338651575"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <BsTelephone/>
                </a>
              </li>
        </div>
       </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageModal;
